import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const smartCheckIn = createAsyncThunk('checkIn/smartCheckIn', async (checkInData: any, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${env('BASE_API_V3')}/smart-checkin`,
      data: checkInData,
    })
  
    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export interface ApprovedCheckInData {
  checkinCode: string
  checkinId: string
  qrJson: string
}

export const getSessionApprovedCheckInData = () => {
  const sessionApprovedCheckInDataInData = sessionStorage.getItem('approvedCheckInData')
  const approvedCheckInDataInData = sessionApprovedCheckInDataInData ? JSON.parse(sessionApprovedCheckInDataInData) : {}

  return approvedCheckInDataInData
}

export const smartCheckInSlice = createSlice({
  name: 'smartCheckIn',
  initialState: {
    approvedCheckInData: getSessionApprovedCheckInData() as ApprovedCheckInData,
    loading: false
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(smartCheckIn.pending, (state) => {
      state.loading = true
    })
    builder.addCase(smartCheckIn.fulfilled, (state, action) => {
      state.approvedCheckInData = action.payload
      state.loading = false

      sessionStorage.setItem('approvedCheckInData', JSON.stringify(state.approvedCheckInData))
    })
    builder.addCase(smartCheckIn.rejected, (state) => {
      state.loading = false
    })
  },
})

export const smartCheckInReducer = smartCheckInSlice.reducer
