import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getAccommodationInfo = createAsyncThunk('reservation/getAccommodationInfo', async (checkinId: string, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${env('BASE_API_V3')}/checkin-approved`,
      params: { checkinId },
    })

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const backupReceiptPDF = createAsyncThunk(
  'reservation/backupReceiptPDF',
  async (data: { hotelId: string; checkinIdsInfo: any }, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'post',
        url: `${env('BASE_API_V3')}/sales-manager/backup-receipt-pdf-without-auth`,
        data: { hotelId: data.hotelId, checkinIdsInfo: data.checkinIdsInfo },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const backupReceiptPDFSlice = createSlice({
  name: 'backupReceiptPDF',
  initialState: {
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(backupReceiptPDF.pending, state => {
      state.loading = true
    })
    builder.addCase(backupReceiptPDF.fulfilled, state => {
      state.loading = false
    })
    builder.addCase(backupReceiptPDF.rejected, state => {
      state.loading = false
    })
  },
})
export const backupReceiptPDFReducer = backupReceiptPDFSlice.reducer
